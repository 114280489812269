@import "../../common/assets/scss/main.scss";

.blog-page {
  padding: 50px 0px 20px 0px;
  .main-div {
    text-decoration: none;
    display: block;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    img.blog-img {
      width: 100%;
      border-radius: 10px 10px 0px 0px;
    }
    .content-div {
      padding: 15px 15px 20px 15px;
      height: 365px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      margin-bottom: 30px;
      h1 {
        font-family: map-get($map: $theme-fonts, $key: montserrat);
        font-weight: 600;
        font-size: 20px;
        margin: 0px;
        color: #444444;
        margin-bottom: auto;
      }
      p.author {
        font-family: map-get($map: $theme-fonts, $key: montserrat);
        font-weight: 600;
        font-size: 13px;
        color: #444444;
        margin-bottom: 10px;
        a {
          text-decoration: none;
          color: #32c070;
        }
      }
      p.sub-des {
        font-family: map-get($map: $theme-fonts, $key: poppins);
        font-weight: 400;
        font-size: 15px;
        margin-bottom: 15px;
        color: #444444;
      }
      a.read-more-btn {
        padding: 10px 25px;
        background-color: $theme-green2;
        color: $theme-green1;
        text-decoration: none;
        font-family: map-get($map: $theme-fonts, $key: poppins);
        font-weight: 500;
        font-size: 14px;
        border-radius: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        svg {
          font-size: 18px;
        }
        &:hover {
          color: #fff;
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 420px) {
  .blog-page {
    padding: 30px 0px;
    .main-div {
      margin-bottom: 30px;
      .content-div {
        height: auto;
        h1 {
          font-size: 18px;
          margin-bottom: 25px;
        }
        p.author {
          font-size: 12px;
        }
        p.sub-des {
          font-size: 14px;
        }
        a.read-more-btn {
          padding: 7px 20px;
          font-size: 13px;
          svg {
            font-size: 18px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 421px) and (max-width: 767px) {
  .blog-page {
    padding: 30px 0px;
    .main-div {
      margin-bottom: 30px;
      .content-div {
        height: auto;
        h1 {
          font-size: 19px;
          margin-bottom: 25px;
        }
        p.author {
          font-size: 12px;
        }
        p.sub-des {
          font-size: 14px;
        }
        a.read-more-btn {
          padding: 7px 20px;
          font-size: 13px;
          svg {
            font-size: 18px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-page {
    .main-div {
      margin-bottom: 30px;
      .content-div {
        height: 375px;
        h1 {
          font-size: 19px;
          margin-bottom: auto;
        }
        p.author {
          font-size: 12.5px;
        }
        p.sub-des {
          font-size: 14.5px;
        }
        a.read-more-btn {
          padding: 8px 24px;
          font-size: 14px;
          svg {
            font-size: 18px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-page {
    .main-div {
      margin-bottom: 30px;
      .content-div {
        height: 435px;
        justify-content: space-between;
        h1 {
          font-size: 19px;
          margin-bottom: 0;
        }
        p.author {
          font-size: 12.5px;
          margin: 0px;
        }
        p.sub-des {
          font-size: 14.5px;
          margin: 0px;
        }
        a.read-more-btn {
          padding: 8px 24px;
          font-size: 14px;
          svg {
            font-size: 18px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .blog-page {
    .main-div {
      margin-bottom: 30px;
      .content-div {
        height: 375px;
        h1 {
          font-size: 19px;
          margin-bottom: auto;
        }
        p.author {
          margin-bottom: 10px;
        }
        p.sub-des {
          margin-bottom: 20px;
        }
        a.read-more-btn {
          padding: 8px 24px;
          font-size: 14px;
          svg {
            font-size: 18px;
          }
        }
      }
    }
  }
}
