@import "../../../../common/assets/scss/main.scss";
.home-page {
  .business-solution {
    padding: 50px 0px;
    .title {
      font-family: map-get($map: $theme-fonts, $key: montserrat);
      color: $theme-green2;
      font-weight: 800;
      font-size: 32px;
      text-align: center;
      margin-bottom: 50px;
    }
    .business-row {
      gap: 30px;
      &:nth-child(3) {
        padding: 50px 0px;
      }
    }
    .business-box {
      .content {
        padding: 20px;
        border-radius: 15px 15px 0px 0px;
        background-color: $theme-green1;
        height: 200px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        h3.heading {
          color: $theme-green2;
          font-family: map-get($map: $theme-fonts, $key: montserrat);
          font-weight: 700;
          font-size: 26px;
        }
        p {
          color: $theme-green2;
          font-family: map-get($map: $theme-fonts, $key: montserrat);
          font-weight: 500;
          font-size: 15px;
        }
        button {
          background-color: transparent;
          padding: 10px 25px;
          outline: none;
          font-family: map-get($map: $theme-fonts, $key: montserrat);
          font-weight: 700;
          border: 1px solid $theme-green2;
          border-radius: 50px;
          line-height: normal;
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: center;
          gap: 15px;
          font-size: 14px;
          img {
            width: 22px;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            -ms-transition: all 0.3s ease;
            transition: all 0.3s ease;
          }
          &:hover {
            img {
              transform: rotate(50deg);
            }
          }
        }
        &.dark {
          background-color: $theme-green2;
          h3.heading {
            color: $theme-green1;
          }
          p {
            color: $theme-green1;
          }
          button {
            border: 1px solid $theme-green1;
            color: $theme-green1;
          }
        }
      }
      .img-box {
        img {
          width: 100%;
        }
      }
    }
  }
}
// Responsive Design Start
@media only screen and (min-width: 320px) and (max-width: 420px) {
  .home-page {
    .business-solution {
      .title {
        font-size: 23px;
        margin-bottom: 40px;
        text-align: left;
        br {
          display: none;
        }
      }
      .business-box {
        .content {
          height: auto;
          h3.heading {
            font-size: 22px;
          }
          p {
            font-size: 14px;
          }
          button {
            padding: 8px 20px;
            font-size: 13px;
            img {
              width: 20px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 421px) and (max-width: 767px) {
  .home-page {
    .business-solution {
      .title {
        font-size: 26px;
        margin-bottom: 40px;
        text-align: left;
        br {
          display: none;
        }
      }
      .business-box {
        .content {
          height: auto;
          h3.heading {
            font-size: 22px;
          }
          p {
            font-size: 15px;
          }
          button {
            padding: 8px 20px;
            font-size: 14px;
            img {
              width: 20px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-page {
    .business-solution {
      .title {
        font-size: 28px;
        margin-bottom: 40px;
        text-align: center;
      }
      .business-row {
        gap: 30px 0px;
      }
      .business-box {
        .content {
          height: auto;
          padding: 15px;
          h3.heading {
            font-size: 23px;
          }
          p {
            font-size: 15px;
          }
          button {
            padding: 9px 20px;
            font-size: 14px;
            img {
              width: 20px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home-page {
    .business-solution {
      .title {
        font-size: 28px;
        margin-bottom: 40px;
        text-align: center;
      }
      .business-row {
        gap: 30px 0px;
      }
      .business-box {
        .content {
          h3.heading {
            font-size: 23px;
          }
          p {
            font-size: 15px;
          }
          button {
            padding: 9px 20px;
            font-size: 14px;
            img {
              width: 20px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .home-page {
    .business-solution {
      .title {
        font-size: 30px;
      }
      // .business-row {
      //   gap: 30px 0px;
      // }
      .business-box {
        .content {
          padding: 15px;
          h3.heading {
            font-size: 25px;
          }
        }
      }
    }
  }
}
// Responsive Design End
