@import "../assets/scss/main.scss";

footer {
  padding: 30px 0px;
  background-color: $theme-green2;
}
footer .menu-div h4 {
  color: #fff;
  font-family: map-get($map: $theme-fonts, $key: montserrat);
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 15px;
}
footer .menu-div ul {
  padding: 0px 0px 0px 0px;
  margin: 0px;
}
footer .menu-div ul li {
  list-style-type: none;
  margin: 10px 0px;
}
footer .menu-div ul li a {
  text-decoration: none;
  color: #fff;
  font-family: map-get($map: $theme-fonts, $key: montserrat);
  font-size: 14px;
  font-weight: 500;
}
.contact-div h4 {
  color: #fff;
  font-family: map-get($map: $theme-fonts, $key: montserrat);
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 15px;
}
.contact-div .call-mail {
  display: flex;
  flex-direction: column;
}
.contact-div .call-mail a {
  text-decoration: none;
  color: #fff;
  font-family: map-get($map: $theme-fonts, $key: montserrat);

  font-weight: 500;
  font-size: 15px;

  margin: 7px 0px;
}
.contact-div .call-mail a img {
  margin-right: 10px;
  width: 25px;
}
.social-media ul {
  padding: 0px;
  margin: 20px 0px 0px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.social-media ul li {
  list-style-type: none;
}
.social-media ul li img {
  width: 30px;
}
.trustpilot img {
  width: 185px;
}
.contact-div {
  padding: 0px 0px 0px 50px;
  border-left: 1px solid #fff;
}
.apps {
  display: flex;
  margin-top: 25px;
  justify-content: space-between;
  list-style: none;
  padding: 0px;
  margin-bottom: 0px;
}
.apps img {
  width: 107px;
}
.copyright-sec {
  padding: 20px 0px;
  background-color: $theme-green2;
  border-top: 1px solid #949494;
}
.copyright-sec p {
  color: #fff;
  margin: 0px;
  font-family: map-get($map: $theme-fonts, $key: montserrat);
  font-weight: 500;
  font-size: 15px;
  text-align: center;
}
.copyright-sec p a {
  text-decoration: none;
  color: #fff;
  font-family: map-get($map: $theme-fonts, $key: montserrat);

  font-weight: 500;
}
.apps {
  justify-content: flex-start;
}
.apps li:first-child {
  margin-right: 15px;
}
.social-media ul {
  justify-content: flex-start;
}
.social-media ul li {
  margin: 0px 10px;
}
.social-media ul li:last-child {
  margin-right: 0px;
}
.social-media ul li:first-child {
  margin-left: 0px;
}
@media only screen and (min-width: 280px) and (max-width: 767px) {
  .contact-div {
    padding: 0px 0px 0px 30px;
  }
  .social-media ul {
    justify-content: flex-start;
  }
  .social-media ul li {
    margin: 0px 10px;
  }
  .social-media ul li:last-child {
    margin-right: 0px;
  }
  .social-media ul li:first-child {
    margin-left: 0px;
  }
  .contact-div {
    margin-bottom: 20px;
  }
  .apps {
    justify-content: flex-start;
  }
  .apps li:first-child {
    margin-right: 15px;
  }
  footer .menu-div {
    margin-bottom: 30px;
  }
  footer .menu-div h4 {
    margin-bottom: 10px;
  }
  footer .menu-div ul li {
    margin: 6px 0px;
  }
}

/* accordion style */
footer .accordion-body {
  padding: 0px;
}
footer .accordion-item {
  color: var(--bs-accordion-color);
  background-color: transparent;
  border: none;
}

/* accordion style End */
@media only screen and (max-width: 767px) {
  footer .menu-div h4 {
    display: none;
  }
  footer .accordion-button {
    color: #fff;
    font-family: map-get($map: $theme-fonts, $key: montserrat);
    font-weight: 600;
    font-size: 14px;
    background-color: transparent;
    outline: transparent;
  }
  footer .contact-div h4 {
    font-size: 16px;
  }
  footer .copyright-sec p {
    font-size: 14px;
  }
  footer .trustpilot img {
    width: 150px;
  }
  footer .contact-div .call-mail a {
    font-size: 14px;
  }
  footer .accordion-button {
    padding: 0px;
  }
  footer .accordion-button:focus {
    box-shadow: none;
  }
  footer .accordion-button:not(.collapsed) {
    color: #fff;
    background-color: transparent;
  }
  footer .menu-div {
    border-bottom: 1px solid #ffffff3b;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
  footer .contact-div {
    margin-bottom: 20px;
    margin-top: 15px;
  }
  footer .accordion-button::after {
    background-image: url(img/accordion.svg);
    width: 13px;
    height: 13px;
    background-size: 13px;
  }
  footer .accordion-button:not(.collapsed)::after {
    background-image: url(img/accordion.svg);
  }
  footer .accordion-body {
    padding-left: 10px;
    padding-top: 10px;
  }
  footer .accordion-button:not(.collapsed) {
    box-shadow: none;
  }
}
@media only screen and (min-width: 768px) {
  footer .collapse:not(.show) {
    display: block !important;
  }
  footer .accordion-button {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  footer .accordion {
    margin-bottom: 20px;
  }
  footer .contact-div {
    padding: 0px 0px 0px 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1399px) {
  footer .menu-div h4,
  footer .contact-div h4 {
    font-size: 17px;
  }
}
