.whatsapp-chat {
  position: fixed;
  bottom: 25px;
  right: auto;
  left: 25px;
  z-index: 999;
  display: flex;
  a {
    background-color: #2eb410;
    padding: 10px;
    border-radius: 50px;
    position: relative;
    img {
      width: 25px;
    }
    span {
      align-items: center;
      -webkit-animation: pulse-red 2s infinite;
      animation: pulse-red 2s infinite;
      background-color: #eb0729;
      border: 2px solid #fff;
      border-radius: 50%;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.29);
      color: #ea0a0c;
      display: flex;
      flex-direction: row;
      font-size: 12px;
      font-weight: 500;
      height: 10px;
      justify-content: center;
      position: absolute;
      right: 5px;
      top: 0px;
      -webkit-transform: scale(1);
      transform: scale(1);
      width: 10px;
      z-index: 999;
    }
    @keyframes pulse-red {
      0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
      }

      70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
      }

      100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
      }
    }
  }
}
