@import "../../../../common/assets/scss/main.scss";
.home-page {
  .partners-count {
    padding: 80px 0px;
    .partners-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      h1 {
        text-align: center;
        font-family: map-get($map: $theme-fonts, $key: montserrat);
        font-weight: 800;
        font-size: 60px;
        line-height: normal;
        margin: 0px;
        background: rgb(23, 51, 3);
        background: linear-gradient(
          180deg,
          rgba(23, 51, 3, 1) 0%,
          rgba(159, 232, 112, 1) 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      p {
        font-family: map-get($map: $theme-fonts, $key: montserrat);
        font-weight: 800;
        font-size: 35px;
        text-align: center;
        margin: 0px;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 420px) {
  .home-page .partners-count {
    padding: 50px 0px;
    .partners-content {
      h1 {
        font-size: 40px;
      }
      p {
        font-size: 20px;
      }
    }
  }
}
@media only screen and (min-width: 421px) and (max-width: 767px) {
  .home-page .partners-count {
    padding: 50px 0px;
    .partners-content {
      h1 {
        font-size: 50px;
      }
      p {
        font-size: 25px;
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-page .partners-count {
    padding: 50px 0px;
    .partners-content {
      h1 {
        font-size: 100px;
      }
      p {
        font-size: 30px;
      }
    }
  }
}
