@import "../../../../common/assets/scss/main.scss";
.home-page {
  .call-back {
    padding: 0px 0px 50px 0px;
    h3 {
      font-family: map-get($map: $theme-fonts, $key: poppins);
      font-weight: 700;
      font-size: 32px;
      text-align: center;
      color: $theme-green2;
      margin-bottom: 20px;
      line-height: 1.5;
    }
    p {
      font-family: map-get($map: $theme-fonts, $key: poppins);
      font-weight: 400;
      font-size: 17px;
      text-align: center;
      color: #222222;
      margin-bottom: 30px;
    }
    form {
      display: flex;
      flex-direction: row;
      align-items: center;
      border: 1px solid $theme-green2;
      padding: 3px;
      width: 85%;
      display: flex;
      margin: 0px auto;
      border-radius: 50px;
      input {
        width: 65%;
        border: none;
        outline: none;
        box-shadow: none;
        background-color: transparent;
        padding: 0px 20px;
        height: 40px;
        font-family: map-get($map: $theme-fonts, $key: poppins);
        font-weight: 600;
        font-size: 15px;
        color: $theme-green2;
        $placeholder: #a1a1a1;
        $fweight: 400;
        &::-webkit-input-placeholder {
          color: $placeholder;
          font-weight: $fweight;
        }
        &::-moz-placeholder {
          color: $placeholder;
          font-weight: $fweight;
        }
        &:-ms-input-placeholder {
          color: $placeholder;
          font-weight: $fweight;
        }
        &:-moz-placeholder {
          color: $placeholder;
          font-weight: $fweight;
        }
      }
      button {
        width: 35%;
        border: none;
        outline: none;
        box-shadow: none;
        background-color: $theme-green2;
        color: $theme-green1;
        padding: 12px 10px;
        border-radius: 50px;
        font-family: map-get($map: $theme-fonts, $key: poppins);
        font-weight: 600;
        font-size: 15px;
      }
    }
    .fmb-btn-group {
      display: flex;
      flex-direction: row;
      gap: 20px;
      justify-content: center;
      align-items: center;
      button {
        width: 150px;
        outline: none;
        background-color: $theme-green2;
        color: #ffffff;
        padding: 10px 25px;
        border-radius: 50px;
        font-family: map-get($map: $theme-fonts, $key: poppins);
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        border: 1px solid $theme-green2;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 420px) {
  .home-page {
    .call-back {
      padding: 0px 0px 50px 0px;
      h3 {
        font-size: 23px;
        br {
          display: none;
        }
      }
      p {
        font-size: 15px;
        br {
          display: none;
        }
      }
      .fmb-btn-group button {
        width: 125px;
        font-size: 13px;
        padding: 8px 15px;
      }
      form {
        width: 100%;
        flex-direction: column;
        border: none;
        gap: 20px;
        input {
          width: 100%;
          border: 1px solid $theme-green2;
          border-radius: 50px;
        }
        button {
          width: 80%;
          font-size: 14px;
        }
      }
    }
  }
}

@media only screen and (min-width: 421px) and (max-width: 767px) {
  .home-page {
    .call-back {
      padding: 0px 0px 50px 0px;
      h3 {
        font-size: 26px;
        br {
          display: none;
        }
      }
      p {
        font-size: 16px;
        br {
          display: none;
        }
      }
      form {
        width: 100%;
        input {
          width: 60%;
        }
        button {
          width: 40%;
          font-size: 14px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-page {
    .call-back {
      padding: 0px 0px 50px 0px;
      h3 {
        font-size: 28px;
      }
      p {
        font-size: 17px;
      }
      form {
        width: 100%;
        input {
          width: 60%;
        }
        button {
          width: 40%;
          font-size: 14px;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home-page {
    .call-back {
      padding: 0px 0px 50px 0px;
      h3 {
        font-size: 30px;
      }
      p {
        font-size: 17px;
      }
      form {
        width: 75%;
        input {
          width: 60%;
        }
        button {
          width: 40%;
          font-size: 14px;
        }
      }
    }
  }
}
