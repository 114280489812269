@import "../../../../common/assets/scss/main.scss";
.home-page {
  .fusionselling-section {
    padding: 75px 0px;
    background-color: $theme-green2;
    h3.heading {
      color: $theme-green1;
      font-family: map-get($map: $theme-fonts, $key: montserrat);
      font-weight: 700;
      font-size: 32px;
      margin-bottom: 45px;
      span {
        color: #ffffff;
      }
    }
    ul.fusionselling-content {
      padding: 0px;
      margin: 0px;
      list-style-type: none;
      li {
        cursor: pointer;
        padding: 30px 0px;
        font-family: map-get($map: $theme-fonts, $key: montserrat);
        &:first-child {
          padding-top: 0px;
        }
        &:last-child {
          padding-bottom: 0px;
        }
        h3 {
          color: #ffffff;
          font-size: 25px;
          font-weight: 700;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          transition: all 0.3s ease;
          svg {
            fill: #ffffff;
            font-size: 50px;
          }
        }
        p {
          color: #ffffff;
          font-size: 15px;
          font-weight: 500;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          transition: all 0.3s ease;
        }
        button {
          outline: none;
          border: none;
          background-color: $theme-green1;
          color: $theme-green2;
          padding: 10px 25px;
          border-radius: 50px;
          font-size: 14px;
          font-weight: 600;
          line-height: normal;
          border: 1px solid $theme-green1;
          &:hover {
            background-color: transparent;
            border: 1px solid $theme-green1;
            color: $theme-green1;
          }
        }
        &:hover,
        &.active {
          h3,
          p {
            color: $theme-green1;
            gap: 20px;
          }
        }
      }
    }
    .fusionselling-img {
      .row {
        gap: 25px 0px;
      }
      img {
        width: 100%;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 420px) {
  .home-page {
    .fusionselling-section {
      padding: 50px 0px;
      h3.heading {
        font-size: 23px;
        margin-bottom: 25px;
        br {
          display: none;
        }
      }
      ul.fusionselling-content {
        margin-bottom: 50px;
        li {
          padding: 15px 0px;
          h3 {
            font-size: 20px;
            svg {
              font-size: 40px;
            }
          }
          p {
            font-size: 14px;
          }
          button {
            padding: 8px 20px;
            font-size: 13px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 421px) and (max-width: 575px) {
  .home-page {
    .fusionselling-section {
      padding: 50px 0px;
      h3.heading {
        font-size: 27px;
        margin-bottom: 25px;
        br {
          display: none;
        }
      }
      ul.fusionselling-content {
        margin-bottom: 50px;
        li {
          padding: 15px 0px;
          h3 {
            font-size: 20px;
            svg {
              font-size: 40px;
            }
          }
          p {
            font-size: 15px;
          }
          button {
            padding: 8px 20px;
            font-size: 13px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .home-page {
    .fusionselling-section {
      padding: 50px 0px;
      h3.heading {
        font-size: 27px;
        margin-bottom: 25px;
        br {
          display: none;
        }
      }
      ul.fusionselling-content {
        margin-bottom: 50px;
        li {
          padding: 15px 0px;
          h3 {
            font-size: 20px;
            svg {
              font-size: 40px;
            }
          }
          p {
            font-size: 15px;
          }
          button {
            padding: 9px 20px;
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-page {
    .fusionselling-section {
      padding: 50px 0px;
      h3.heading {
        font-size: 28px;
        margin-bottom: 25px;
      }
      ul.fusionselling-content {
        margin-bottom: 50px;
        li {
          padding: 15px 0px;
          h3 {
            font-size: 20px;
            svg {
              font-size: 40px;
            }
          }
          p {
            font-size: 15px;
          }
          button {
            padding: 9px 20px;
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home-page {
    .fusionselling-section {
      padding: 50px 0px;
      h3.heading {
        font-size: 28px;
        margin-bottom: 25px;
      }
      ul.fusionselling-content {
        margin-bottom: 50px;
        li {
          padding: 15px 0px;

          button {
            padding: 9px 20px;
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .home-page {
    .fusionselling-section {
      ul.fusionselling-content {
        margin-bottom: 50px;
        li {
          padding: 15px 0px;
          h3 {
            font-size: 23px;
          }
        }
      }
    }
  }
}
