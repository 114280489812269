@import "../../common/assets/scss/main.scss";

.blog-details {
  padding: 50px 0px;
  img.main-img {
    width: 100%;
  }
  .date-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0px;
    p {
      font-family: map-get($map: $theme-fonts, $key: montserrat);
      font-weight: 600;
      font-size: 13px;
      margin: 0px;
      color: #444444;
      a {
        text-decoration: none;
        color: #32c070;
      }
    }
    .social-icon {
      display: flex;
      gap: 10px;
      a {
        img {
          width: 25px;
        }
      }
    }
  }
  .aside-content {
    h1 {
      font-family: map-get($map: $theme-fonts, $key: montserrat);
      font-weight: 700;
      font-size: 25px;
      margin: 0px 0px 20px 0px;
    }
  }
  .main-content {
    padding-top: 25px;
  }
  .aside-content,
  .main-content {
    .content-div {
      h3 {
        font-family: map-get($map: $theme-fonts, $key: montserrat);
        font-weight: 600;
        font-size: 20px;
        margin: 10px 0px 20px 0px;
        display: inline-block;
      }
      ul {
        list-style-type: none;
        padding: 0px;
        margin: 0px;
        li {
          margin-bottom: 15px;
          p {
            font-family: map-get($map: $theme-fonts, $key: poppins);
            font-weight: 400;
            font-size: 16px;
            margin: 0px;
            color: #444444;
            span {
              font-weight: 600;
            }
            a {
              text-decoration: none;
              color: #32c070;
              font-weight: 500;
            }
            &.download-app {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              gap: 15px;
              margin-top: 20px;
              img {
                width: 107px;
              }
            }
          }
        }
        &.custom-list {
          list-style-type: disc;
          padding: 0px 0px 0px 30px;
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 420px) {
  .blog-details {
    padding: 30px 0px;
    .date-icon {
      display: flex;
      flex-direction: column;
      gap: 12px;
      p {
        font-size: 12px;
      }
      .social-icon a img {
        width: 22px;
      }
    }
    .aside-content {
      margin-top: 25px;
      h1 {
        font-size: 22px;
      }
    }
    .aside-content,
    .main-content {
      .content-div {
        h3 {
          font-size: 18px;
        }
        ul {
          li {
            margin-bottom: 20px;
            p {
              font-size: 14px;
              text-align: justify;
            }
          }
        }
      }
    }
    .main-content {
      padding-top: 0px;
    }
  }
}

@media only screen and (min-width: 421px) and (max-width: 767px) {
  .blog-details {
    padding: 30px 0px;
    .date-icon {
      display: flex;
      flex-direction: row;
      p {
        font-size: 12px;
      }
      .social-icon a img {
        width: 23px;
      }
    }
    .aside-content {
      margin-top: 25px;
      h1 {
        font-size: 22px;
      }
    }
    .aside-content,
    .main-content {
      .content-div {
        h3 {
          font-size: 19px;
        }
        ul {
          li {
            margin-bottom: 20px;
            p {
              font-size: 15px;
              text-align: justify;
            }
          }
        }
      }
    }
    .main-content {
      padding-top: 0px;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details {
    padding: 35px 0px;
    .aside-content {
      margin-top: 25px;
      h1 {
        font-size: 23px;
      }
    }
    .aside-content,
    .main-content {
      .content-div {
        ul {
          li {
            margin-bottom: 20px;
            p {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-details {
    .aside-content {
      h1 {
        font-size: 23px;
      }
    }
    .aside-content,
    .main-content {
      .content-div {
        ul {
          li {
            margin-bottom: 20px;
            p {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}
