@import "../../../../common/assets/scss/main.scss";
.home-page {
  .merchants {
    padding: 50px 0px 50px 0px;
    background-color: $theme-green1;
    h1 {
      margin: 0px 0px 50px 0px;
      font-family: map-get($map: $theme-fonts, $key: poppins);
      font-weight: 700;
      font-size: 32px;
      color: $theme-green2;
      text-align: center;
    }
    .swiper.merchants-swiper {
      padding-bottom: 100px;
      .swiper-wrapper {
        .swiper-slide {
          .swiper-box {
            background-color: #ffffff;
            padding: 15px;
            border-radius: 10px;
            height: 225px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .header {
              margin-bottom: 20px;
              display: flex;
              align-items: center;
              gap: 10px;
              img {
                width: 50px;
                height: 50px;
                object-fit: cover;
                border-radius: 50%;
              }
              .name-desg {
                p {
                  margin: 0px;
                  font-family: map-get($map: $theme-fonts, $key: poppins);
                  font-weight: 600;
                  font-size: 20px;
                  color: #222222;
                }
                span {
                  margin: 0px;
                  font-family: map-get($map: $theme-fonts, $key: poppins);
                  font-weight: 500;
                  font-size: 16px;
                  color: #222222;
                }
                ul {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: flex-start;
                  padding: 0px;
                  list-style-type: none;
                  margin: 0px 0px 10px 0px;
                  gap: 5px;
                  li {
                    color: #ffc400;
                    svg {
                      font-size: 20px;
                    }
                  }
                }
              }
            }
            p.descri {
              margin: 0px;
              font-family: map-get($map: $theme-fonts, $key: poppins);
              font-weight: 400;
              font-size: 14px;
              color: #222222;
            }
            span.quotes-img {
              text-align: right;
              display: block;
              img {
                width: 50px;
              }
            }
          }
        }
      }

      .swiper-button-prev,
      .swiper-button-next {
        bottom: 10px;
        top: auto;
        border: 2px solid $theme-green2;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        &:after {
          color: $theme-green2;
          font-size: 20px;
          font-weight: bold;
        }
      }
      .swiper-button-prev {
        right: 51%;
        left: auto;
      }
      .swiper-button-next {
        left: 51%;
        right: auto;
      }
    }
  }
}
// responsive design Start
@media only screen and (min-width: 320px) and (max-width: 420px) {
  .home-page {
    .merchants {
      h1 {
        font-size: 23px;
      }
      .swiper.merchants-swiper .swiper-wrapper .swiper-slide .swiper-box {
        height: 235px;
        .header {
          img {
            width: 45px;
            height: 45px;
          }
          .name-desg {
            p {
              font-size: 17px;
            }
            span {
              font-size: 14px;
            }
          }
        }
        span.quotes-img img {
          width: 45px;
        }
      }
    }
  }
}
@media only screen and (min-width: 421px) and (max-width: 767px) {
  .home-page {
    .merchants {
      h1 {
        font-size: 27px;
      }
      .swiper.merchants-swiper .swiper-wrapper .swiper-slide .swiper-box {
        height: 235px;
        .header {
          img {
            width: 45px;
            height: 45px;
          }
          .name-desg {
            p {
              font-size: 17px;
            }
            span {
              font-size: 14px;
            }
          }
        }
        span.quotes-img img {
          width: 45px;
        }
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .home-page {
    .merchants {
      h1 {
        font-size: 30px;
      }
      .swiper.merchants-swiper .swiper-wrapper .swiper-slide .swiper-box {
        height: 235px;
        .header {
          img {
            width: 45px;
            height: 45px;
          }
          .name-desg {
            p {
              font-size: 18px;
            }
            span {
              font-size: 14px;
            }
          }
        }
        span.quotes-img img {
          width: 45px;
        }
      }
    }
  }
}
// responsive design End
