@import "../../../../common/assets/scss/main.scss";
.home-page {
  .recent-blog {
    padding: 50px 0px;
    h3.heading {
      font-size: 32px;
      font-family: map-get($map: $theme-fonts, $key: montserrat);
      color: $theme-green2;
      margin-bottom: 50px;
      text-align: center;
      font-weight: 700;
    }
    .swiper.recent-swiper {
      padding: 0px 30px 100px 30px;
      .swiper-wrapper {
        .swiper-slide {
          .blog-box {
            box-shadow: rgb(149 157 165 / 12%) 0px 8px 24px;
            text-decoration: none;
            background-color: #ffffff;
            position: relative;
            display: block;
            border-radius: 10px;
            img {
              width: 100%;
              border-radius: 10px 10px 0px 0px;
            }
            .content {
              padding: 20px 15px;
              border-radius: 10px;
              height: 300px;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              h3 {
                font-size: 20px;
                font-family: map-get($map: $theme-fonts, $key: montserrat);
                color: $theme-green2;
                font-weight: 700;
                margin-bottom: auto;
              }
              p {
                font-size: 14px;
                font-family: map-get($map: $theme-fonts, $key: montserrat);
                margin-bottom: 30px;
                font-weight: 500;
                color: #222222;
              }
              .footer-div {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-end;
                .date-name {
                  span {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    font-size: 13px;
                    font-family: map-get($map: $theme-fonts, $key: montserrat);
                    color: #222222;
                    &:first-child {
                      font-weight: 600;
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      a {
                        color: #32c070;
                      }
                    }
                    &:last-child {
                      font-weight: 400;
                    }
                  }
                }
                a {
                  font-size: 14px;
                  font-family: map-get($map: $theme-fonts, $key: montserrat);
                  color: #222222;
                  font-weight: 700;
                  text-decoration: none;
                  svg {
                    fill: #222222;
                  }
                }
              }
            }
          }
        }
      }
      .swiper-button-prev,
      .swiper-button-next {
        bottom: 10px;
        top: auto;
        border: 2px solid $theme-green2;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        &:after {
          color: $theme-green2;
          font-size: 20px;
          font-weight: bold;
        }
      }
      .swiper-button-prev {
        right: 51%;
        left: auto;
      }
      .swiper-button-next {
        left: 51%;
        right: auto;
      }
    }
  }
}

// Responsive Design start
@media only screen and (min-width: 320px) and (max-width: 420px) {
  .home-page {
    .recent-blog {
      h3.heading {
        font-size: 23px;
        margin-bottom: 30px;
      }
      .swiper.recent-swiper {
        padding-left: 0px;
        padding-right: 0px;
        .swiper-wrapper {
          .swiper-slide {
            .blog-box {
              margin: 0px 10px;
              .content {
                height: auto;
                h3 {
                  font-size: 18px;
                  margin-bottom: 20px;
                }
                p {
                  font-size: 13.5px;
                  margin-bottom: 20px;
                }
                .footer-div {
                  .date-name span {
                    font-size: 12px;
                  }
                  a {
                    font-size: 13px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 421px) and (max-width: 767px) {
  .home-page {
    .recent-blog {
      h3.heading {
        font-size: 26px;
        margin-bottom: 30px;
      }
      .swiper.recent-swiper {
        padding-left: 0px;
        padding-right: 0px;
        .swiper-wrapper {
          .swiper-slide {
            .blog-box {
              margin: 0px 10px;
              .content {
                height: auto;
                h3 {
                  font-size: 20px;
                  margin-bottom: 20px;
                }
                p {
                  margin-bottom: 20px;
                }
                .footer-div {
                  a {
                    font-size: 13px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-page {
    .recent-blog {
      h3.heading {
        font-size: 28px;
        margin-bottom: 30px;
      }

      .swiper.recent-swiper {
        padding-left: 0px;
        padding-right: 0px;
        .swiper-wrapper {
          .swiper-slide {
            .blog-box {
              margin: 0px 15px;
              .content {
                height: 320px;
                h3 {
                  font-size: 18px;
                  font-weight: 600;
                }
                p {
                  margin-bottom: 20px;
                  font-size: 13px;
                }
                .footer-div {
                  gap: 20px;
                  flex-direction: column;
                  align-items: flex-start;
                  a {
                    font-size: 13px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home-page {
    .recent-blog {
      h3.heading {
        font-size: 30px;
        margin-bottom: 30px;
      }

      .swiper.recent-swiper {
        padding-left: 0px;
        padding-right: 0px;
        .swiper-wrapper {
          .swiper-slide {
            .blog-box {
              margin: 0px 5px;
              .content {
                height: 300px;
                h3 {
                  font-size: 16px;
                }
                p {
                  margin-bottom: 20px;
                  font-size: 13px;
                }
                .footer-div {
                  gap: 20px;

                  a {
                    font-size: 13px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .home-page {
    .recent-blog {
      .swiper.recent-swiper {
        padding-left: 0px;
        padding-right: 0px;
        .swiper-wrapper {
          .swiper-slide {
            .blog-box {
              margin: 0px 5px;
              .content {
                height: 300px;
                h3 {
                  font-size: 20px;
                }
                p {
                  margin-bottom: 20px;
                  font-size: 13px;
                }
                .footer-div {
                  gap: 20px;

                  a {
                    font-size: 13px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
// Responsive Design End
