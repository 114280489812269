@import "../../assets/scss/main.scss";
nav.main-header {
  padding: 12px 0px;
  @media (min-width: 992px) {
    &.navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
      justify-content: flex-end;
    }
  } // Common  Responsive
  &.theme-bg {
    background-color: $theme-green2;
  }
  &.gray-bg {
    background-color: $gray-bg;
    .navbar-nav {
      .nav-link {
        color: $theme-green2;
      }
      &.nav-login-div {
        .nav-link,
        button {
          border: 1px solid $theme-green2;
          color: #ffffff;
          background-color: $theme-green2;
          &:first-child {
            border: 1px solid $theme-green2;
            color: $theme-green2;
            background-color: transparent;
          }
        }
        .theme-btn .checkbox-wrapper-54 {
          input:checked + .slider svg,
          .slider {
            svg {
              fill: $theme-green2;
            }
            span.theme-name {
              color: $theme-green2;
            }
          }
        }
      }
    }
    .burger-menu {
      .line-menu {
        background-color: $theme-green2;
      }
    }
  }
  .logo img {
    width: 175px;
  }
  .navbar-nav {
    gap: 10px;
    .nav-link {
      font-family: map-get($map: $theme-fonts, $key: montserrat);
      font-size: 14px;
      font-weight: 600;
      color: #ffffff;
    }
    &.nav-menu-div {
      margin: 0px auto;
    }
    &.nav-login-div {
      @media only screen and (max-width: 991px) {
        margin-top: 20px;
      }
      display: flex;
      flex-direction: row;
      align-items: center;
      .nav-link {
        border: 1px solid #ffffff;
        border-radius: 50px;
        padding-left: 20px;
        padding-right: 20px;
        width: fit-content;
      }
      button {
        border: 1px solid #ffffff;
        border-radius: 50px;
        width: fit-content;
        background-color: transparent;
        color: #ffffff;
        padding: 7px 25px;
        font-family: map-get($map: $theme-fonts, $key: montserrat);
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
        &:first-child {
          border: none;
          @media only screen and (max-width: 991px) {
            border: 1px solid #ffffff;
          }
        }
      }
    }
  }
  .navbar-nav .nav-link.active,
  .navbar-nav .show > .nav-link {
    color: $theme-green1;
  }
}

@media only screen and (min-width: 320px) and (max-width: 991px) {
  nav.main-header {
    border-top: none;
  }
}
@media only screen and (min-width: 320px) and (max-width: 991px) {
  nav.main-header {
    // nav
    .logo img {
      width: 150px;
    }
    .burger-menu {
      width: 20px;
      height: 17px;
    }
    .navbar-nav {
      gap: 0px;
      &:first-child {
        margin-top: 20px;
      }
      .nav-link {
        font-size: 14px;
        margin: 0px;
      }
      &.nav-login-div {
        flex-direction: row;
        align-items: flex-start;
        gap: 25px;
        .theme-btn .checkbox-wrapper-54 {
          input:checked + .slider svg,
          .slider {
            padding-left: 0px;
            padding-right: 0px;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  nav.main-header {
    // nav
    .logo img {
      width: 150px;
    }
    .navbar-nav {
      .nav-link {
        font-size: 14px;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 575px) {
  nav.main-header {
    .logo img {
      width: 150px;
    }
    .navbar-nav {
      .nav-link {
        font-size: 14px;
      }
    }
  }
}
.toast-message {
  font-family: map-get($map: $theme-fonts, $key: montserrat);
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}
