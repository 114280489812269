@import "../../../../common/assets/scss/main.scss";
.home-page {
  .slider {
    padding: 20px 0px;
    height: 950px;
    background-color: $theme-green2;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    @keyframes pulse {
      100% {
        transform: scale(2.5);
        opacity: 0;
      }
    }
    .pulse {
      height: 800px;
      width: 800px;
      background: linear-gradient(#9fe870, #9fe870);
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      top: auto;
      bottom: -60%;
      border-radius: 50%;
      display: grid;
      place-items: center;
      font-size: 50px;
      color: #ffffff;
      opacity: 0.9;
      svg {
        fill: #9fe870;
      }
      &:before,
      &:after {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: #9fe870;
        border-radius: 50%;
        z-index: 1;
        opacity: 0.7;
      }
      &:before {
        animation: pulse 2s ease-out infinite;
      }
      &:after {
        animation: pulse 2s 1s ease-out infinite;
      }
    } //pulse
    .content {
      z-index: 1;
      position: relative;
      h1 {
        font-family: map-get($map: $theme-fonts, $key: gilroyextrabold);
        color: #ffffff;
        text-align: center;
        font-size: 35px;
        font-weight: 600;
        line-height: 1.3;
        margin-bottom: 30px;
      }
      ul {
        padding: 10px 0px;
        margin: 0px;
        list-style-type: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 30px;
        li {
          color: #ffffff;
          font-family: map-get($map: $theme-fonts, $key: gilroysemibold);
          font-size: 17px;
          font-weight: 600;
          &.desk-hide {
            display: none;
          }
        }
      }
      .btn-group {
        display: flex;
        flex-direction: row;
        gap: 15px;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
      }
      button {
        // background-color: $theme-green1;
        // color: $theme-green2;
        // padding: 10px 30px;
        // border: none;
        // outline: none;
        // box-shadow: none;
        // font-family: map-get($map: $theme-fonts, $key: gilroysemibold);
        // font-size: 15px;
        // font-weight: 500;
        // display: flex;
        // margin: 0px auto;
        // margin-top: 30px;
        // cursor: pointer;
        // border-radius: 50px;
        appearance: none;
        backface-visibility: hidden;
        background-color: $theme-green1;
        border-radius: 50px;
        border-style: none;
        box-shadow: #173303 0 4px 9px;
        box-sizing: border-box;
        color: $theme-green2;
        cursor: pointer;
        display: flex;
        margin: 15px 0px;
        font-family: map-get($map: $theme-fonts, $key: gilroysemibold);
        font-size: 15px;
        font-weight: 600;
        letter-spacing: normal;
        line-height: normal;
        outline: none;
        overflow: hidden;
        padding: 10px 25px;
        position: relative;
        text-align: center;
        text-decoration: none;
        transform: translate3d(0, 0, 0);
        transition: all 0.3s;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
        vertical-align: top;
        white-space: nowrap;
        border: 1px solid $theme-green1;
        width: fit-content;
        &:hover {
          background-color: $theme-green2;
          color: $theme-green1;
          opacity: 1;
          border: 1px solid $theme-green1;
          transform: translateY(0);
          transition-duration: 0.35s;
          box-shadow: #9fe870 2px 3px 0px 0px;
        }
        &:active {
          transform: translateY(2px);
          transition-duration: 0.35s;
        }
        // &.first-btn {
        //   background-color: transparent;
        //   border: none;
        //   color: #ffffff;
        // }
      }
    }
    .img-box {
      z-index: 1;
      position: relative;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 420px) {
  .home-page {
    .slider {
      height: 650px;
      .content {
        h1 {
          font-size: 25px;
          br {
            display: none;
          }
          margin-bottom: 20px;
        }
        ul {
          flex-wrap: wrap;
          gap: 20px 30px;
          &.mob-hide {
            display: none;
          }
          li {
            &.desk-hide {
              display: block;
            }
            font-size: 14px;
          }
        }
        button {
          font-size: 14px;
          padding: 8px 20px;
        }
      }
      .img-box {
        overflow: scroll;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none; /* Safari and Chrome */
        }
        img {
          width: 700px;
          max-width: none;
        }
      }
      .pulse {
        bottom: -38%;
        width: 320px;
        height: 320px;
      }
    }
  }
}
@media only screen and (min-width: 421px) and (max-width: 575px) {
  .home-page {
    .slider {
      height: 700px;
      .content {
        h1 {
          font-size: 26px;
          br {
            display: none;
          }
          margin-bottom: 20px;
        }
        ul {
          flex-wrap: wrap;
          gap: 20px 30px;
          &.mob-hide {
            display: none;
          }
          li {
            &.desk-hide {
              display: block;
            }
            font-size: 15px;
          }
        }
        button {
          font-size: 15px;
          padding: 8px 20px;
        }
      }
      .img-box {
        overflow: scroll;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none; /* Safari and Chrome */
        }
        img {
          width: 750px;
          max-width: none;
        }
      }
      .pulse {
        bottom: -45%;
        width: 421px;
        height: 421px;
      }
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .home-page {
    .slider {
      height: 675px;
      .content {
        h1 {
          font-size: 32px;
          br {
            display: none;
          }
          margin-bottom: 20px;
        }
        ul {
          flex-wrap: wrap;
          gap: 20px 30px;

          li {
            font-size: 15px;
          }
        }
        button {
          font-size: 15px;
          padding: 8px 20px;
        }
      }
      .img-box {
        overflow: scroll;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none; /* Safari and Chrome */
        }
        img {
          width: 750px;
          max-width: none;
        }
      }
      .pulse {
        bottom: -75%;
        width: 576px;
        height: 576px;
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-page {
    .slider {
      height: 650px;
      .content {
        h1 {
          font-size: 33px;
        }
        ul {
          flex-wrap: wrap;
          gap: 20px 30px;

          li {
            font-size: 15.5px;
          }
        }
        button {
          font-size: 15px;
          padding: 8px 20px;
        }
      }
      .pulse {
        bottom: -75%;
        width: 600px;
        height: 600px;
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home-page {
    .slider {
      height: 800px;
      .content {
        h1 {
          font-size: 33px;
        }
        ul {
          flex-wrap: wrap;
          gap: 20px 30px;

          li {
            font-size: 16px;
          }
        }
        button {
          font-size: 15px;
          padding: 8px 20px;
        }
      }
      .pulse {
        bottom: -60%;
        width: 650px;
        height: 650px;
      }
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .home-page {
    .slider {
      height: 900px;
      .content {
        h1 {
          font-size: 33px;
        }
        ul {
          li {
            font-size: 16px;
          }
        }
        button {
          font-size: 15px;
          padding: 8px 20px;
        }
      }
      .pulse {
        bottom: -60%;
        width: 725px;
        height: 725px;
      }
    }
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .home-page {
    .slider {
      .pulse {
        bottom: -65%;
      }
    }
  }
}
