// theme color
$theme-green1: #9fe870;
$theme-green2: #173303;
$gray-bg: #eeeeee;
$white: #fff;
$black: #000;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
// Fonts
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/gilroy/Gilroy-SemiBold.woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gilroyextrabold";
  src: url("../fonts/gilroy/Gilroy-ExtraBold.woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

$theme-fonts: (
  poppins: (
    "Poppins",
    sans-serif,
  ),
  montserrat: (
    "Montserrat",
    sans-serif,
  ),
  gilroysemibold: (
    "Gilroy",
    sans-serif,
  ),
  gilroyextrabold: (
    "Gilroyextrabold",
    sans-serif,
  ),
);

$themes: (
  light: (
    bg: white,
    text: black,
  ),
  dark: (
    whitecolor: #fff,
    black: $black,
    dark1: #070f1c,
    dark2: #131927,
    dark3: rgb(8 21 34),
    dark4: #0a1929,
    dark5: #02111f,
    none: none,
  ),
);

@mixin themed() {
  @each $theme, $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}
