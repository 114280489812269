@import "../../assets/scss/main.scss";

.get-start-modal.modal {
  .modal-dialog {
    @media (min-width: 576px) {
      max-width: 375px;
    }
    .modal-content {
      border-radius: 15px !important;
      .modal-header {
        padding: 15px 15px 10px 15px;
        border: none;
        position: relative;
        justify-content: center;
        .modal-title {
          font-family: map-get($map: $theme-fonts, $key: montserrat);
          font-weight: 600;
          font-size: 20px;
        }
        span {
          position: absolute;
          top: -25px;
          right: 0px;
          display: flex;
          svg {
            font-size: 22px;
            cursor: pointer;
            fill: #ffffff;
          }
        }
      }
      .modal-body {
        padding: 10px 5px 20px 5px;
        form {
          .form-floating {
            margin-bottom: 30px;
            input {
              font-family: map-get($map: $theme-fonts, $key: montserrat);
              font-weight: 600;
              font-size: 14px;
              border: 1px solid #c1c1c1;
              box-shadow: none;
              height: 45px;
              padding: 10px;
              min-height: 45px;
              color: #000;
            }
            .invalid-feedback {
              font-family: map-get($map: $theme-fonts, $key: montserrat);
              font-weight: 500;
              font-size: 13px;
            }
            label {
              font-family: map-get($map: $theme-fonts, $key: montserrat);
              font-weight: 500;
              font-size: 14px;
              padding: 10px;
              color: #555a64;
            }
            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active {
              -webkit-background-clip: text;
              -webkit-text-fill-color: #000000;
              transition: background-color 5000s ease-in-out 0s;
              font-family: map-get($map: $theme-fonts, $key: montserrat);
              font-weight: 600;
              font-size: 14px;
            }
          }
          .form-floating > .form-control-plaintext ~ label,
          .form-floating > .form-control:focus ~ label,
          .form-floating > .form-control:not(:placeholder-shown) ~ label {
            color: $theme-green2;
            top: -8px;
            font-size: 15px;
            font-weight: 600;
            padding: 5px 10px;
            height: 30px;
          }
          button {
            background-color: $theme-green2;
            color: $theme-green1;
            border: none;
            outline: none;
            box-shadow: none;
            font-family: map-get($map: $theme-fonts, $key: montserrat);
            font-weight: 600;
            font-size: 14px;
            width: 100%;
            border-radius: 50px;
            text-align: center;
            padding: 12px;
          }
        }
      }
    }
  }
}
